import React from "react";
import _ from "lodash";
import { Grid, List } from "@material-ui/core";
import { PDProjectChecklistInterface, PDProjectInterface, PDProjectStatusInterface, PDProjectTaskInterface } from "interfaces/ProductDevelopment";
import { makeAxiosCall } from "utils";
import ProjectProgressTask from "./ProjectProgressTask";

interface PDProjectProgressProps {
    checklist: PDProjectChecklistInterface,
    project: PDProjectInterface,
    setProject: (project: PDProjectInterface) => void,
    refreshData: () => void,
}

const PDProjectChecklist = ({ checklist, project, setProject, refreshData }: PDProjectProgressProps) => {
    const [tempProject, setTempProject] = React.useState<PDProjectInterface>(project);

    const projectStatuses = React.useMemo(() => {
        return [...new Set(checklist.tasks.map((task) => task.projectStatus))].sort((a, b) => a.id - b.id);
    }, [project]);

    React.useEffect(() => {
      setTempProject(project);
    }, [project]);

    const saveTask = async (task: PDProjectTaskInterface, refresh: boolean = false) => {
        if(task.id) {
            makeAxiosCall(
                "post",
                "pd/task/" + task.id,
                task
            ).then((result) => {
                console.log(result);
                if (refresh) {
                  refreshData();
                }
            });
        } else {
            await makeAxiosCall(
                "post",
                "pd/task",
                {
                    name: task.name,
                    projectId: project.id,
                    projectStatusId: task.projectStatus.id,
                    taskStatusId: task.taskStatus.id
                }
            ).then((result) => {
                if(result && result.data && result.data.id) {
                    task.id = result.data.id;
                }
                console.log(result);
            });
        }

        let updatedProject = {...tempProject};
        let newTaskList = [];
        for (let ti = 0; ti < checklist.tasks.length; ti++) {
            const existingTaskData = checklist.tasks[ti];
            if(existingTaskData.id == task.id || existingTaskData.name == task.name) {
                newTaskList.push(task);
            } else {
                newTaskList.push(existingTaskData);
            }
        }

        updatedProject.checklists.find((list) => list.id == checklist.id).tasks = newTaskList;

        setProject(updatedProject);
        setTempProject(updatedProject);
    }

    const buildList = (status: PDProjectStatusInterface) => {
        let listItems = [];

        for (let lti = 0; lti < checklist.tasks.length; lti++) {
            const task = checklist.tasks[lti];

            if(task.projectStatus.id == status.id) {
                listItems.push(<ProjectProgressTask 
                  key={task.id + "-task-item"} 
                  task={task} 
                  saveTask={saveTask} 
                  project={tempProject}
                  refreshData={refreshData}
                />);
            }
        }

        if(!listItems.length) return;

        return (

            <List key={status.id + "-list"} style={{width: "100%"}}>
                <h3>{status.name}</h3>
                {
                    listItems
                }
            </List>
            
        )
    };

    const buildLists = () => {
        let lists = [];

        for (let lti = 0; lti < projectStatuses.length; lti++) {
            const status = projectStatuses[lti];
            
            lists.push(buildList(status));
        }

        return (
            <Grid item xs={12} container>
                {lists}    
            </Grid>
        )
    };

    return buildLists();
};

export default PDProjectChecklist;
