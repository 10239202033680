import React from "react";
import Loading from "components/utils/Loading";
import _ from "lodash";

import {
    Button,
    Card,
    Snackbar,
    Grid,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
    TextField,
    Paper,
    CircularProgress,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";

import "scss/components/Admin/EditUser.scss";

import { UserContext } from "context/User";
import { makeAxiosCall } from "../../utils";
import { buildUser, UserAccountPermissionsInterface, UserInterface } from "interfaces/User";
import PermissionDisplay from "components/utils/PermissionDisplay";
import { buildCompanyInfo, CompanyInterface } from "interfaces/Company";
import ViewApplication from "components/Account/Wizard/ViewApplication";
import { buildNewResellerInvite, NewResellerInviteInterface } from "interfaces/Invite";

const enum EditUserProcesses {
    adding,
    removing,
    denying,
    updating,
    loading,
    none,
}

const EditUser = () => {
    const {
        fetchTempUser,
        accountLevels,
        loadFullUserData
    } = React.useContext(UserContext);

    const userUid = window.location.pathname.split("/edit-user/")[1];
    const [user, setUser] = React.useState<UserInterface>();
    const [selectedCompany, setSelectedCompany] = React.useState<CompanyInterface>();
    const [currentProcess, setCurrentProcess] = React.useState<EditUserProcesses>(EditUserProcesses.none);
    const [members, setMembers] = React.useState<UserInterface[]>();
    const [inputValue, setInputValue] = React.useState<string>("");
    const [potentialCompanyNames, setPotentialCompanyNames] = React.useState<Array<string>>([]);
    const [potentialCompanies, setPotentialCompanies] = React.useState<Array<{ name: string, id: number }>>([]);
    const [snackbar, setSnackbar] = React.useState({
        isOpen: false,
        message: "",
        severity: "success",
    });
    const [level, setLevel] = React.useState<UserAccountPermissionsInterface>(accountLevels[2]);
    const [viewingApplication, setViewingApplication] = React.useState(false);
    const [existingInvite, setExistingInvite] = React.useState<NewResellerInviteInterface>();

    React.useEffect(() => {
        fetchUser();
        fetchAllCompanies();
    }, []);

    const fetchUser = async () => {
        await loadFullUserData(userUid).then((userData) => {
            const userLevel = accountLevels.find((level) => level.name === userData.accountLevel.name);
            setUser(userData);
            setLevel(userLevel);
            if (userData.company) {
                setSelectedCompany(userData.company);
            }

            makeAxiosCall(
                "get",
                `reseller-invites-email/${userData.email}`
            ).then((inviteResult) => {
                if (inviteResult.data) setExistingInvite(buildNewResellerInvite(inviteResult.data));
            });
        });

    };

    const fetchAllCompanies = () => {
        makeAxiosCall(
            "get",
            "companies/simple"
        ).then(async res => {
            const companyList = [];
            const companyNameList = [];
            for (let ci = 0; ci < res.data.length; ci++) {
                const company = res.data[ci];
                companyList.push({ id: company.id, name: company.name });
                companyNameList.push(company.name);
            }
            setPotentialCompanyNames(companyNameList);
            setPotentialCompanies(companyList);
        });
    };

    const selectCompany = (companyName) => {
        const selectedCompany = potentialCompanies.find((c) => c.name === companyName);
        if (selectedCompany) {
            if (user.company && user.company.id && selectedCompany.id == user.company.id) {
                setSelectedCompany({ ...user.company });
            } else {
                setCurrentProcess(EditUserProcesses.loading);
                makeAxiosCall(
                    "get",
                    "company/" + selectedCompany.id
                ).then(async res => {
                    const company = await buildCompanyInfo(res.data);
                    const membersList: UserInterface[] = await Promise.all(_.map(
                        res.data.members,
                        (member) => {
                            return buildUser(member);
                        }
                    ));

                    setSelectedCompany({ ...company });
                    setMembers(membersList);
                    setCurrentProcess(EditUserProcesses.none);
                }
                );
            }
        }
    };

    const updateUserCompany = (companyId) => {
        setCurrentProcess(EditUserProcesses.adding);
        const newAccountLevel = members.length == 0 ?
            accountLevels.find((level) => level.name == "Admin") :
            accountLevels.find((level) => level.name == "Restricted");

        makeAxiosCall(
            "post",
            `account/${userUid}`,
            {
                associatedCompanyId: companyId,
                accountLevelId: newAccountLevel.id,
                accountSignupStatus: "active"
            }
        ).then(response => {
            if (response.status === 200) {
                setSnackbar({
                    isOpen: true,
                    message: "Updated User Company",
                    severity: "success",
                });
                setUser({ ...user, company: selectedCompany, accountSignupStatus: "active" });
                setLevel(newAccountLevel);
                if (newAccountLevel.name == "Restricted") {
                    notifyAdmins();
                } else {
                    makeAxiosCall(
                        "post",
                        "email/user-approved",
                        {
                            to: {
                                id: user.uid,
                                email: user.email,
                                companyId: selectedCompany.id,
                            },
                            companyName: selectedCompany.name,
                        }
                    );
                }
                setCurrentProcess(EditUserProcesses.none);
            } else {
                setSnackbar({
                    isOpen: true,
                    message: "Something went wrong. Please try again.",
                    severity: "error",
                });
                setCurrentProcess(EditUserProcesses.none);
            }
        }).catch(error => {
            console.error(error);
            setSnackbar({
                isOpen: true,
                message: "Something went wrong. Please try again.",
                severity: "error",
            });
            setCurrentProcess(EditUserProcesses.none);
        });
    };

    const updateUserLevel = () => {
        setCurrentProcess(EditUserProcesses.updating);

        makeAxiosCall(
            "post",
            `account/${userUid}`,
            { accountLevelId: level.id }
        ).then(response => {
            if (response.status === 200) {
                setSnackbar({
                    isOpen: true,
                    message: "Saved User",
                    severity: "success",
                });
                setCurrentProcess(EditUserProcesses.none);
            } else {
                setSnackbar({
                    isOpen: true,
                    message: "Something went wrong. Please try again.",
                    severity: "error",
                });
                setCurrentProcess(EditUserProcesses.none);
            }
        }).catch(error => {
            console.error(error);
            setSnackbar({
                isOpen: true,
                message: "Something went wrong. Please try again.",
                severity: "error",
            });
            setCurrentProcess(EditUserProcesses.none);
        });
    };

    const removeUserFromCompany = () => {
        setCurrentProcess(EditUserProcesses.removing);

        makeAxiosCall(
            "post",
            `account/${userUid}`,
            { associatedCompanyId: "null" },
        ).then(response => {
            if (response.status === 200) {
                setSnackbar({
                    isOpen: true,
                    message: "Updated User Company",
                    severity: "success",
                });
                setUser({ ...user, company: null });
                setCurrentProcess(EditUserProcesses.none);
            } else {
                setSnackbar({
                    isOpen: true,
                    message: "Something went wrong. Please try again.",
                    severity: "error",
                });
                setCurrentProcess(EditUserProcesses.none);
            }
        }).catch(error => {
            console.error(error);
            setSnackbar({
                isOpen: true,
                message: "Something went wrong. Please try again.",
                severity: "error",
            });
            setCurrentProcess(EditUserProcesses.none);
        });
    };

    const denyReview = () => {
        setCurrentProcess(EditUserProcesses.denying);

        makeAxiosCall(
            "post",
            `account/${userUid}`,
            {
                accountSignupStatus: "declined",
            }
        ).then(response => {
            if (response.status === 200) {
                setSnackbar({
                    isOpen: true,
                    message: "Denied user application",
                    severity: "success",
                });
                setUser({ ...user, accountSignupStatus: "declined" });

                makeAxiosCall(
                    "post",
                    "email/user-denied",
                    {
                        to: {
                            id: user.uid,
                            email: user.email,
                        }
                    }
                );
                setCurrentProcess(EditUserProcesses.none);
            } else {
                setSnackbar({
                    isOpen: true,
                    message: "Something went wrong. Please try again.",
                    severity: "error",
                });
                setCurrentProcess(EditUserProcesses.none);
            }
        }).catch(error => {
            console.error(error);
            setSnackbar({
                isOpen: true,
                message: "Something went wrong. Please try again.",
                severity: "error",
            });
            setCurrentProcess(EditUserProcesses.none);
        });
    };

    const saveUserButton = () => {
        return (<Button
            onClick={() => { updateUserLevel(); }}
            type="submit"
            style={{ margin: 20, width: "calc(100% - 40px)" }}
            variant="contained"
            color="primary"
            className="btn otl"
        >
            {currentProcess == EditUserProcesses.updating ? (
                <CircularProgress
                    color="inherit"
                    size={24}
                />
            ) : ("Save Permissions")
            }
        </Button>);
    };

    const notifyAdmins = async () => {
        // Sends new user email about being restricted, sends admins email about new user added
        const adminLevelId = accountLevels.find((level) => level.name == "Admin").id;
        const admins = members.filter((member) => member.accountLevel.id == adminLevelId);
        const adminData = admins.map((admin) => {
            return {
                id: admin.uid,
                email: admin.email,
                companyId: selectedCompany.id
            };
        });

        if (admins.length > 0) {
            await makeAxiosCall(
                "post",
                "email/user-added",
                {
                    newUserEmail: user.email,
                    newUserId: user.uid,
                    admins: adminData,
                    companyName: selectedCompany.name,
                    userName: user.firstName + " " + user.lastName,
                }
            );
        } else {
            console.log("Error: no admins in company");
        }
    };

    const renderAccountStatus = (status) => {
        if (status === "active") {
            return "status highlight blue";
        } else if (status === "declined") {
            return "status highlight red";
        } else if (status === "needs review" || status === "applied") {
            return "status highlight purple";
        }
    };

    const openApplication = () => {
        setViewingApplication(true);
    };

    const closeApplication = () => {
        setViewingApplication(false);
    };

    return (
        <div className="settings view">
            {user && user.uid ? (
                <div>
                    <h1>
                        Editing&nbsp;
                        <span className="underline-blue" style={{ paddingLeft: 5 }}>
                            {user.displayName}
                        </span>
                    </h1>
                    {/* {generalStatusList()} */}

                    <Paper
                        elevation={2}
                        style={{ margin: "15px 0", padding: 20 }}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <span style={{ fontWeight: "bold", fontSize: 16, marginRight: 5 }}>Email: </span> {user.email}
                            </Grid>
                            <Grid item container sm={4}>
                                <Grid item xs={12}><h3>Account Created:</h3></Grid>
                                <Grid item xs={12}>{user.createdAt ? user.createdAt.toLocaleDateString() : "Unknown"}</Grid>
                            </Grid>
                            <Grid item container sm={4}>
                                <Grid item xs={12}><h3>User Given Company:</h3></Grid>
                                <Grid item xs={12}>{user.userProvidedCompanyName ? user.userProvidedCompanyName : "None"}</Grid>
                            </Grid>
                            <Grid item container sm={4}>
                                <Grid item xs={12}><h3>Account Status:</h3></Grid>
                                <Grid item xs={12}>
                                    <div className={user.accountSignupStatus ? renderAccountStatus(user.accountSignupStatus) : ""}>
                                        {user.accountSignupStatus == "active" ? "Active" :
                                            (user.accountSignupStatus == "declined" ? "Declined" :
                                                (user.accountSignupStatus == "applied" ? "Pending Application" :
                                                    "Needs Review"
                                                )
                                            )
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                            {existingInvite && (
                                <Grid item container>
                                    <Grid item xs={12} style={{ marginTop: 20 }}>
                                        <span style={{ fontWeight: "bold" }}>Invited to Become a Reseller: </span>
                                        This user was invited to apply by <strong>{existingInvite.createdBy.displayName}</strong> of <strong>{existingInvite.createdBy.company?.name ?? "unknown company"}</strong>.
                                        The company name provided was <strong>{existingInvite.companyName ?? "Unspecified company"}</strong>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Paper>

                    {user && (user.hasApplication) && (
                        <Paper
                            elevation={2}
                            style={{ margin: "15px 0", padding: 20 }}
                        >
                            <ViewApplication userUid={userUid} open={viewingApplication} close={closeApplication} showDeny={user.accountSignupStatus == "applied" || user.accountSignupStatus == "needs review"} />
                            <Grid container alignItems="center">
                                <Grid item sm={9}>
                                    <h3>{user.accountSignupStatus == "applied" || user.accountSignupStatus == "needs review" ? "This user has a pending reseller application." : "This user's application has been " + (user.accountSignupStatus == "denied"  || user.accountSignupStatus == "declined" ? "denied" : "approved") + " ."}</h3>
                                    <p>
                                        {user.accountSignupStatus == "applied" || user.accountSignupStatus == "needs review" ?
                                            "View the application. Once the application has been approved" +
                                            "and the company is added to Fishbowl, come back and add this" +
                                            "user to the company." : ""
                                        }
                                    </p>
                                </Grid>
                                <Grid item sm={3}>
                                    <Button
                                        type="submit"
                                        style={{ width: "100%", margin: "1.5em 0" }}
                                        variant="contained"
                                        color="primary"
                                        onClick={openApplication}
                                    >
                                        View
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}

                    <div style={{ marginRight: 10, marginBottom: 10 }}>
                        <h2 style={{ margin: 0, width: "100%", textAlign: "left" }}>
                            Associated Company - {user.company ? user.company.name : "No Company"}
                        </h2>

                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginTop: 15
                        }}>
                            <FormControl
                                fullWidth
                            >
                                <Autocomplete
                                    // disablePortal
                                    id="on-behalf-select"
                                    options={potentialCompanyNames}
                                    value={selectedCompany ? selectedCompany.name : "Unknown"}
                                    onChange={(event: any, newValue: string | null) => {
                                        if (newValue) {
                                            selectCompany(newValue);
                                            setInputValue(newValue);
                                        } else {
                                            selectCompany(user.company.name);
                                        }
                                    }}
                                    inputValue={inputValue}
                                    onInputChange={(event, newValue) => {
                                        setInputValue(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Select Company" />}
                                />
                            </FormControl>
                        </div>
                    </div>

                    {user && selectedCompany && (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {
                                    user.company && user.company.id !== selectedCompany.id && (<p style={{ textAlign: "center" }}>{members && members.length > 0 ?
                                        "This user will be added as a restricted member." :
                                        "This user will become this company's admin."
                                    }</p>)
                                }
                                <Button
                                    type="submit"
                                    style={{ width: "100%", margin: "1.5em 0" }}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        updateUserCompany(selectedCompany.id);
                                    }}
                                    className="btn"
                                    disabled={currentProcess == EditUserProcesses.loading || (user.company && user.company.id == selectedCompany.id)}
                                >
                                    {currentProcess == EditUserProcesses.loading || currentProcess == EditUserProcesses.adding ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={24}
                                        />
                                    ) : ("Add to Company")
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    )}

                    {user && user.accountSignupStatus == "needs review" && (
                        <Paper
                            elevation={2}
                            style={{ margin: "15px 0", padding: 20 }}
                        >
                            <Grid container alignItems="center">
                                <Grid item sm={9}>
                                    <h3>Don&apos;t recognize the company?</h3>
                                    <p>
                                        Deny the user&apos;s request if the company isn&apos;t an existing
                                        reseller. They will be directed to fill out application
                                        materials and go through the onboarding process.
                                    </p>
                                </Grid>
                                <Grid item sm={3}>
                                    <Button
                                        type="submit"
                                        style={{ width: "100%", margin: "1.5em 0" }}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            denyReview();
                                        }}
                                        className="btn red"
                                    >
                                        {currentProcess == EditUserProcesses.denying ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={24}
                                            />
                                        ) : ("Deny")
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}

                    {user && user.company && (
                        <Button
                            type="submit"
                            style={{ width: "100%", margin: "1.5em 0" }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                removeUserFromCompany();
                            }}
                            className="btn red"
                            disabled={!user.company}
                        >
                            {currentProcess == EditUserProcesses.removing ? (
                                <CircularProgress
                                    color="inherit"
                                    size={24}
                                />
                            ) : ("Remove From Company")
                            }
                        </Button>
                    )}

                    {user && user.company && (
                        <>
                            <h2 style={{ margin: 0, width: "100%", textAlign: "left", paddingBottom: 20 }}>
                                User Permissions
                            </h2>
                            <Card>
                                <Grid container className="address-line" style={{ textAlign: "center", paddingTop: "1.5em" }} alignItems="center" spacing={2}>
                                    <Grid item xs={12} >
                                        <FormControl
                                            style={{ width: "96%" }}
                                            className="state"
                                            variant="outlined"
                                        >
                                            <InputLabel id="demo-simple-select-outlined-label" style={{ backgroundColor: "white" }}>
                                                Account Level
                                            </InputLabel>
                                            <Select
                                                onChange={(e: any) => setLevel(e.target.value)}
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={level}
                                            >
                                                {_.map(accountLevels, (level) => {
                                                    return (
                                                        <MenuItem key={level.name} value={level}>
                                                            {level.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {<PermissionDisplay levelName={level.name} />}
                                </Grid>
                                {saveUserButton()}
                            </Card>
                        </>
                    )}

                    <Button
                        onClick={
                            () => fetchTempUser(user.uid)
                        }
                        type="submit"
                        style={{ width: "100%", marginTop: 10 }}
                        variant="contained"
                        color="primary"
                        className="btn otl"
                    >
                        <i className="far fa-ghost" style={{ marginRight: 5 }} />View Portal as {user.firstName}
                    </Button>
                </div>
            ) : <Loading height="40vh" title="Connecting to Inventory System" position="relative" />}

            <Snackbar
                open={snackbar.isOpen}
                autoHideDuration={3000}
                onClose={(_, reason) => {
                    if (reason === "clickaway") {
                        return;
                    }

                    // For Future Devon - This snackbar is the toast
                    setSnackbar({ ...snackbar, isOpen: false });
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    severity={snackbar.severity == "success" ? "success" : snackbar.severity == "error" ? "error" : snackbar.severity == "info" ? "info" : "warning"}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default EditUser;
