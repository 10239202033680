import React from "react";
import _ from "lodash";
import { UserContext } from "context/User";
import { makeAxiosCall, stringToColor } from "utils";
import { Avatar, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { CSSTransition } from "react-transition-group";
import { UserInterface } from "interfaces/User";
import PermissionDisplay from "components/utils/PermissionDisplay";
import Loading from "components/utils/Loading";
import { CompanyInterface } from "interfaces/Company";

interface EditMemberProps {
    selectedUser: UserInterface,
    company: CompanyInterface
    editCallback?: () => void,
}

const EditMember = ({ selectedUser, company, editCallback }: EditMemberProps) => {

    const { currentUser, accountLevels, isAdmin } = React.useContext(UserContext);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [currentProcess, setCurrentProcess] = React.useState("");
    const [salesMember, setSalesMember] = React.useState(selectedUser.salesTeamMember);
    const [availableAccountLevels, setAvailableAccountLevels] = React.useState(accountLevels);
    const [level, setLevel] = React.useState(availableAccountLevels[2]);

    React.useEffect(() => {
        if (!isAdmin) {
            setAvailableAccountLevels(accountLevels.filter((level) => level.name !== "Super Admin"));
        }
    }, [currentUser]);

    React.useEffect(() => {
        if (selectedUser.accountLevel) {
            setLevel(accountLevels.find((level) => level.name == selectedUser.accountLevel.name));
        }
    }, [selectedUser]);

    const updateUserLevel = () => {

        makeAxiosCall(
            "post",
            `account/${selectedUser.uid}`,
            { accountLevelId: level.id, salesTeamMember: salesMember }
        ).then(response => {
            if (response.status === 200) {
                if (editCallback) {
                    editCallback();
                }
            } else {
                setErrorMessage("Something went wrong. Please try again.");
                setCurrentProcess("");
            }
        }).catch(error => {
            console.error(error);
            setErrorMessage("Something went wrong. Please try again.");
            setCurrentProcess("");
        });
    };

    const removeUserFromCompany = () => {
        makeAxiosCall(
            "post",
            `account/${selectedUser.uid}`,
            { associatedCompanyId: "null" },
        ).then(response => {
            if (response.status === 200) {
                if (editCallback) {
                    editCallback();
                }
            } else {
                setErrorMessage("Something went wrong. Please try again.");
                setCurrentProcess("");
            }
        }).catch(error => {
            console.error(error);
            setErrorMessage("Something went wrong. Please try again.");
            setCurrentProcess("");
        });
    };

    const splitName = (name) => {
        if (name !== undefined) {
            return name.split(" ");
        }
    };

    return selectedUser ? (
        <div className="user-info" style={{ margin: "3em", maxWidth: 1000 }}>
            <div className="base-info">
                <Avatar
                    className="avatar-default large"
                    style={{
                        background: stringToColor(selectedUser.displayName),
                    }}
                >
                    {splitName(selectedUser.displayName)[0].charAt(0)}
                </Avatar>
                <h3>{selectedUser.displayName}</h3>
                <Grid container>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                        <div className="highlight blue">
                            {`${company.name} ${selectedUser.accountLevel.name}`}
                        </div>
                    </Grid>
                    <Grid item xs={4}></Grid>
                </Grid>
                <p>{selectedUser.email}</p>
            </div>
            <hr />
            <CSSTransition
                in={errorMessage.length > 0}
                unmountOnExit
                timeout={200}
            >
                <p style={{ color: "#ff4d4d", width: "100%" }}>{errorMessage}</p>
            </CSSTransition>
            <Grid container className="address-line" style={{ textAlign: "center", paddingTop: "1em" }} justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl
                        style={{ width: "100%" }}
                        className="state"
                        variant="outlined"
                    >
                        <InputLabel id="demo-simple-select-outlined-label" style={{ backgroundColor: "white" }}>
                            Account Level
                        </InputLabel>
                        <Select
                            onChange={(e: any) => setLevel(e.target.value)}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={level}
                        >
                            {_.map(availableAccountLevels, (level) => {
                                return (
                                    <MenuItem key={level.id} value={level}>
                                        {level.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={salesMember}
                                onChange={() => {
                                    setSalesMember(!salesMember)
                                }}
                                color="primary"
                                size="small"
                            />
                        }
                        label={"Sales Team Member"}
                        classes={{ label: "search-filter-option" }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <PermissionDisplay levelName={level.name} />
                </Grid>
                <Grid item container justifyContent="center" spacing={2} xs={12}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Button
                            type="submit"
                            style={{ width: "100%" }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setErrorMessage("");
                                setCurrentProcess("update");
                                updateUserLevel();
                            }}
                            className="btn blue"
                        >
                            {currentProcess === "update" ?
                                <CircularProgress
                                    color="inherit"
                                    size={24}
                                /> :
                                "Save"
                            }
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Button
                            type="submit"
                            style={{ width: "100%" }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setErrorMessage("");
                                setCurrentProcess("remove");
                                removeUserFromCompany();
                            }}
                            className="btn red"
                        >
                            {currentProcess === "remove" ?
                                <CircularProgress
                                    color="inherit"
                                    size={24}
                                /> :
                                "Remove User"
                            }
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    ) : (
        <Loading height="40vh" title="" position="relative" />
    );
};

export default EditMember;