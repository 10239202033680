import React from "react";
import _ from "lodash";
import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, MenuItem, OutlinedInput, Select, TextField, Tooltip } from "@material-ui/core";
import Loading from "components/utils/Loading";
import CompanySearchResult from "./CompanySearchResult";
import { List, WindowScroller } from "react-virtualized";
import { useWindowWidth } from "hooks/useWindowSize";
import "react-virtualized/styles.css";

interface CompanySearchProps {
    allCompanies: any[],
}

interface CompanySearchFiltersInterface {
    reps: boolean,
    resellers: boolean,
    other: boolean,
    approved: boolean,
    notApproved: boolean,
    denied: boolean,
    inactive: boolean,
    active: boolean,
    expiring: boolean
}

const CompanySearch = ({ allCompanies }: CompanySearchProps) => {
    const [input, setInput] = React.useState("");
    const [showFilters, setShowFilters] = React.useState(false);
    const [selectedFilters, setSelectedFilters] = React.useState<CompanySearchFiltersInterface>(
        localStorage.getItem("company-filters") ? 
            JSON.parse(localStorage.getItem("company-filters")) : 
            {
                reps: false,
                resellers: false,
                other: false,
                approved: false,
                notApproved: false,
                denied: false,
                inactive: false,
                active: false,
                expiring: false
            }
    );
    const [filteredList, setFilteredList] = React.useState([]);
    const [sortMethods, setSortMethods] = React.useState<string[]>([]);
    const [loading, setLoading] = React.useState(true);
    const windowWidth = useWindowWidth();

    const windowScrollerRef = React.useRef<WindowScroller>();

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 800,
                width: 250,
            },
        },
    };

    const sortOptions = [
        {
            label: "Name (A - Z)",
            value: "name",
            order: "asc",
        },
        {
            label: "Name (Z - A)",
            value: "name",
            order: "desc",
        },
        {
            label: "Members (Low - High)",
            value: "memberCount",
            order: "asc",
        },
        {
            label: "Members (High - Low)",
            value: "memberCount",
            order: "desc",
        },
        {
            label: "Invites (Low - High)",
            value: "inviteCount",
            order: "asc",
        },
        {
            label: "Invites (High - Low)",
            value: "inviteCount",
            order: "desc",
        },
        {
            label: "Last Updated (Old - New)",
            value: "result.approvalStatus.lastUpdated",
            order: "asc",
        },
        {
            label: "Last Updated (New - Old)",
            value: "result.approvalStatus.lastUpdated",
            order: "desc",
        },
    ];


    React.useEffect(() => {
        if (!showFilters && usingFilter()) {
            setShowFilters(true);
        }
    }, []);

    React.useEffect(() => {
        if (allCompanies) {
            filterCompanies();
            setLoading(false);
        }
    }, [allCompanies]);

    React.useEffect(() => {
        filterCompanies();
    }, [selectedFilters]);

    React.useLayoutEffect(() => {
        // Adjust the scroll view when filters are opened or closed
        if (windowScrollerRef && windowScrollerRef.current) {
            windowScrollerRef.current.updatePosition();
        }
    }, [showFilters]);

    const usingFilter = () => {
        return (
            selectedFilters.reps ||
            selectedFilters.resellers ||
            selectedFilters.other ||
            selectedFilters.approved ||
            selectedFilters.notApproved ||
            selectedFilters.denied ||
            selectedFilters.inactive ||
            selectedFilters.active ||
            selectedFilters.expiring
        );
    }

    const filterCompanies = () => {
        // Only apply filters once one is selected
        const applyFilters = _.some(selectedFilters, Boolean);

        if (applyFilters) {
            let reps = [];
            let resellers = [];
            let other = [];
            let approved = [];
            let notApproved = [];
            let denied = [];
            let inactive = [];
            let active = [];
            let expiring = [];

            if (selectedFilters.reps) {
                reps = allCompanies.filter((company) => company.type === "Rep");
            }
            if (selectedFilters.resellers) {
                resellers = allCompanies.filter((company) => company.type === "Reseller");
            }
            if (selectedFilters.other) {
                other = allCompanies.filter((company) => company.type != "Rep" && company.type != "Reseller");
            }
            const companyTypes = selectedFilters.reps || selectedFilters.resellers || selectedFilters.other ?
                _.union(reps, resellers, other) : allCompanies;

            if (selectedFilters.approved) {
                approved = companyTypes.filter((company) => company.approved);
            }
            if (selectedFilters.notApproved) {
                notApproved = companyTypes.filter((company) => !company.approved);
            }
            if (selectedFilters.denied) {
                denied = companyTypes.filter(
                    (company) =>
                        company.approvalStatus.certificateDenialReason ||
                        company.approvalStatus.agreementDenialReason ||
                        company.approvalStatus.infoFormDenialReason
                );
            }
            const approval = selectedFilters.approved || selectedFilters.notApproved || selectedFilters.denied ?
                _.union(approved, notApproved, denied) : companyTypes;

            if (selectedFilters.inactive) {
                inactive = approval.filter((company) => company.memberCount == 0 && company.inviteCount == 0);
            }
            if (selectedFilters.active) {
                active = approval.filter(((company) => company.memberCount > 0 || company.inviteCount > 0));
            }
            if (selectedFilters.expiring) {
                expiring = approval.filter(((company) => !!company.approvalStatus.expirationDate));
            }
            const returnable = selectedFilters.inactive || selectedFilters.active || selectedFilters.expiring ?
                _.union(inactive, active, expiring) : approval;

            setFilteredList(returnable);
        } else {
            setFilteredList(allCompanies);
        }
    };

    const sortCompanies = (searchResults) => {
        let returnable;

        if (sortMethods.length > 0) {
            const dateSorter = company => new Date(company.approvalStatus.lastUpdated);
            returnable = _.orderBy(
                searchResults,
                sortMethods.map((method) => method.substring(0, 4) == "Last" ?
                    dateSorter :
                    sortOptions.find((option) => option.label == method).value),
                sortMethods.map((method) => sortOptions.find((option) => option.label == method).order),
            );
        } else {
            returnable = _.orderBy(searchResults, ["name"], ["asc"]);
        }

        return returnable;
    };

    const renderFilterOption = (option, label, tooltip) => {
        return (
            <Grid item xs={12} >
                <Tooltip title={tooltip} placement="top" arrow>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedFilters[option]}
                                onChange={() => {
                                    const newFilters = {
                                        ...selectedFilters,
                                        [option]: !selectedFilters[option]
                                    };

                                    localStorage.setItem("company-filters", JSON.stringify(newFilters));
                                    setSelectedFilters(newFilters);
                                }}
                                color="primary"
                                size="small"
                            />
                        }
                        label={label}
                        classes={{ label: "search-filter-option" }}
                    />
                </Tooltip>
            </Grid>
        );
    };

    const renderSortOptions = () => {
        return (
            <FormControl style={{ width: "100%" }}>
                <Select
                    key="sortMethods"
                    multiple
                    value={sortMethods}
                    renderValue={() => (
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                            {sortMethods.map((method) => (
                                <Chip key={method} label={method} style={{ marginRight: 5 }} />
                            ))}
                        </Box>
                    )}
                    onChange={(e: React.ChangeEvent<{
                        name?: string;
                        value: string[];
                    }>) => {
                        setSortMethods(e.target.value);
                    }}
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                >
                    {sortOptions.map(
                        (option) => {
                            const index = sortMethods.findIndex((n) => n == option.label);
                            return (
                                <MenuItem
                                    key={option.label}
                                    value={option.label}
                                >
                                    <span className={index > -1 ? "green" : "grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: index > -1 ? "green" : "grey" }}>{index > -1 ? index + 1 : " "}</span>
                                    {option.label}
                                </MenuItem>
                            );
                        }
                    )}
                </Select>
            </FormControl>
        );
    };

    const searchResults = React.useMemo(() => {
        const searchInput = input.toLowerCase();

        const hits = _.filter(filteredList, (company) => {
            return _.includes(company.name.toLowerCase(), searchInput);
        });

        return sortCompanies(hits);
    }, [filteredList, input, sortMethods]);

    const renderRow = ({ index, key, style }) => {
        return (
            <div style={style} key={key} >
                <div style={{ padding: 5 }}>
                    <CompanySearchResult company={searchResults[index]} mobileMode={windowWidth < 700} />
                </div>
            </div>
        );
    };

    const renderPlaceholder = () => {
        return <p className="body-message center-text">No Companies Found</p>;
    };

    return allCompanies ? (
        <div className="grid-search">
            <h3 className="title">Companies</h3>
            <div className="search-field">
                <TextField
                    label="Company Search"
                    variant="outlined"
                    onChange={(e) => setInput(e.target.value)}
                    fullWidth
                />
            </div>
            <Grid container className="container-spacing">
                <Grid item container alignItems="center" xs={12}>
                    <Grid item md={9} xs={7}><h3>Filter and Sort {`(${searchResults.length})`}{usingFilter() && !showFilters && <span> - <span style={{textDecoration: 'underline'}}>Filters are applied</span></span>}</h3></Grid>
                    <Grid item md={3} xs={5}>
                        <Button
                            variant={showFilters ? "contained" : "outlined"}
                            color={showFilters ? "primary" : "secondary"}
                            onClick={() => setShowFilters(!showFilters)}
                            fullWidth
                        >
                            {showFilters ? "Hide Filters" : "Show Filters"}
                        </Button>
                    </Grid>
                </Grid>
                {showFilters && (
                    <>
                        <Grid item container xs={9}>
                            <Grid item container alignContent="flex-start" xs={4}>
                                <Grid item xs={12}>Company Type</Grid>
                                {renderFilterOption("reps", "Reps", "Rep Companies")}
                                {renderFilterOption("resellers", "Resellers", "Reseller Companies")}
                                {renderFilterOption("other", "Other", "Basic, Internal, etc.")}
                            </Grid>
                            <Grid item container alignContent="flex-start" xs={4}>
                                <Grid item xs={12}>Approval Status</Grid>
                                {renderFilterOption("approved", "Approved", "Application, info form, reseller agreement and certificate are all approved.")}
                                {renderFilterOption("notApproved", "Not Approved", "Missing one or more document approvals")}
                                {renderFilterOption("denied", "Denied", "One or more documents have been denied")}
                            </Grid>
                            <Grid item container alignContent="flex-start" xs={4}>
                                <Grid item xs={12}>Portal Status</Grid>
                                {renderFilterOption("inactive", "Inactive", "Not using the portal: no members or invites")}
                                {renderFilterOption("active", "Active", "Has at least one member or active invite")}
                                {renderFilterOption("expiring", "Expiring", "Has an expiration date")}
                            </Grid>
                        </Grid>
                        <Grid item container alignContent="flex-start" xs={3}>
                            <Grid item xs={12}>Sort By</Grid>
                            <Grid item xs={12}>
                                {renderSortOptions()}
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>

            <Grid container className="results">
                {windowWidth > 700 && <Grid container item xs={12} className="center-text grid-search-headers">
                    <Grid xs={3} item className="result-name">
                        Name
                    </Grid>
                    <Grid item xs={1}>
                        Members
                    </Grid>
                    <Grid item xs={1}>
                        Invites
                    </Grid>
                    <Grid item xs={1}>
                        Type
                    </Grid>
                    <Grid item xs={1}>
                        Info
                    </Grid>
                    <Grid item xs={1}>
                        Agreement
                    </Grid>
                    <Grid item xs={1}>
                        Certificate
                    </Grid>
                    <Grid item xs={2}>
                        Last Updated
                    </Grid>
                    <Grid item xs={1}>
                        View
                    </Grid>
                </Grid>}
                {loading ?
                    (<Loading height="40vh" title="Connecting to Inventory System" position="relative" />) :
                    (<WindowScroller ref={windowScrollerRef}>
                        {({ height, width, isScrolling, onChildScroll, scrollTop }) => {
                            return <List
                                autoHeight
                                width={width}
                                height={height}
                                isScrolling={isScrolling}
                                onScroll={onChildScroll}
                                scrollTop={scrollTop}
                                rowHeight={windowWidth < 700 ? 90 : 60}
                                rowRenderer={renderRow}
                                noRowsRenderer={renderPlaceholder}
                                rowCount={searchResults.length}
                                overscanRowCount={3}
                            />;
                        }}
                    </WindowScroller>)
                }
            </Grid>
        </div>
    ) : (
        <Loading height="40vh" title="" position="relative" />
    );
};

export default CompanySearch;
