import React from "react";
import { Grid, Paper, Tooltip, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

/*
  company: {
    id: number,
    name: string,
    type: string,                                         // company_type.name
    memberCount: number,
    inviteCount: number,
    approvalStatus: {
      agreementComplete: boolean,
      agreementApproved: boolean,
      agreementDenialReason: string,
      certificateUploaded: boolean,
      certificateApproved: boolean,
      certificateDenialReason: string,
      infoFormComplete: boolean,
      infoFormApproved: boolean,
      infoFormDenialReason: string,
      applicationComplete: boolean,
      applicationApproved: boolean,
      applicationDenialReason: string,
    }
  }
*/

interface CompanySearchResultProps {
    company: any,
    mobileMode: boolean,
}

const CompanySearchResult = ({ company, mobileMode }: CompanySearchResultProps) => {
    const navigate = useNavigate();

    const renderApprovalIcon = (complete, approved, denied) => {
        let icon: JSX.Element;
        let message: string;
        if (denied) {
            icon = <i className="far fa-close tomato"></i>;
            message = "Denied";
        } else if (approved) {
            icon = <i className="fa-solid fa-check green"></i>;
            message = "Approved";
        } else if (complete) {
            icon = <i className="fa-solid fa-circle-exclamation orange"></i>;
            message = "Pending Approval";
        } else {
            icon = <i className="fa-solid fa-circle-question blue"></i>;
            message = "Incomplete";
        }
        return (
            <Link
                to={{
                    pathname: `/edit-company/${company.id}`
                    
                }}
                state={{ startingPage: "Approval" }}
                className="no-underline"
            >
                <Tooltip
                    title={message}
                >
                    {icon}
                </Tooltip>
            </Link>
        );
    };

    let issueFlag;
    if (company.memberCount === 0 && company.inviteCount === 0) {
        issueFlag = (
            <Tooltip
                title="No members or pending invites"
            >
                <i className="fa-solid fa-flag icon big-icon tomato"></i>
            </Tooltip>
        );
    }

    let approvalSection;
    let companyNotApproved;
    if (company.approvalStatus) {
        approvalSection = (
            <>
                <Grid item xs={1} className="center-text">
                    {renderApprovalIcon(
                        company.approvalStatus.infoFormComplete,
                        company.approvalStatus.infoFormApproved,
                        company.approvalStatus.infoFormDenialReason
                    )}
                </Grid>
                <Grid item xs={1} className="center-text">
                    {renderApprovalIcon(
                        company.approvalStatus.agreementComplete,
                        company.approvalStatus.agreementApproved,
                        company.approvalStatus.agreementDenialReason
                    )}
                </Grid>
                <Grid item xs={1} className="center-text">
                    {renderApprovalIcon(
                        company.approvalStatus.certificateUploaded,
                        company.approvalStatus.certificateApproved,
                        company.approvalStatus.certificateDenialReason
                    )}
                </Grid>
            </>
        );

        companyNotApproved = !company.approvalStatus.certificateApproved ||
            !company.approvalStatus.agreementApproved ||
            !company.approvalStatus.infoFormApproved;
    }

    return (
        <Paper key={company.id} elevation={2} className="grid-search-result" onClick={() => {
            navigate(`/edit-company/${company.id}`);
        }}>
            <Grid container item xs={12} className={mobileMode ? "" : "center-text"}>
                <Grid item container justifyContent="space-between" xs={12} md={3} className="result-name">
                    <Grid item xs={11}>
                        <Typography noWrap variant={mobileMode ? "h6" : "body2"}>
                            {company.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        {issueFlag}
                    </Grid>
                </Grid>
                <Grid item md={1} xs={7}>
                    <Link
                        to={{
                            pathname: `/edit-company/${company.id}`
                        }}
                        state={{ startingPage: "Users" }}
                        className="no-underline"
                    >
                        {mobileMode ? "Members: " : ""}{company.memberCount}
                    </Link>
                </Grid>
                {!mobileMode && <Grid item xs={1}>
                    <Link
                        to={{
                            pathname: `/edit-company/${company.id}`
                        }}
                        state={{ startingPage: "Invites" }}
                        className="no-underline"
                    >
                        {company.inviteCount}
                    </Link>
                </Grid>}
                <Grid item md={1} xs={4}>
                    {company.type}
                </Grid>
                {!mobileMode && approvalSection}
                {!mobileMode && <Grid item xs={2}>
                    {companyNotApproved && company.approvalStatus.lastUpdated ? company.approvalStatus.lastUpdated : ""}
                </Grid>}
                <Grid item xs={1}>
                    <Link to={"/edit-company/" + company.id} className="no-underline">
                        <i className="fas fa-eye blue"></i>
                    </Link>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default CompanySearchResult;